<template>
  <div class="commentItem">
    <div class="itemBox">
      <!-- <el-avatar
        shape="square"
        :size="50"
        src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
      ></el-avatar> -->
      <!-- <div class="wl-avatar">
        {{ uti.setAvatar(it.nickName) }}
      </div> -->

      <avatar-item :addr="it.userId" :name="it.nickName"></avatar-item>

      <div class="box">
        <div class="name">{{ it.nickName }}</div>
        <div class="text">
          {{ it.contentText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import AvatarItem from "../avatar/avatar-item.vue";
export default {
  name: "treeMenu",
  props: {
    it: {
      type: Object,
    },
  },
  components: { AvatarItem },
  mounted() {},
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      input: "",
      hfIfShow: false,
    };
  },
  methods: {
    // 回复评论
    async replyComments() {
      if (!this.store.user) {
        this.$message.error("请先登录");
      }
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/send/subComment`,
        {
          // 用户id
          userId: this.store.user.userId,
          // 评论id
          commentId: this.it.id,
          // 评论内容
          contentText: this.input,
          // 回复目标用户id
          targetUserId: this.it.userId,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.$emit("aaaaa");
    },
  },
};
</script>

<style lang="less" scoped>
.commentItem {
  // margin-top: 10px;
  .itemBox {
    display: flex;
    .box {
      // width: 898px;
      flex: 1;
      margin-left: 16px;
      .name {
        /* 金牌讲师 */
        height: 20px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
      .text {
        // min-height: 0px;
        margin-top: 10px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #333333;
      }
    }
  }
}
</style>
