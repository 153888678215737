<template>
  <div class="right-item">
    <div class="ind" @click="goVideoDec()">
      <div class="jing" v-if="it.boutiqueStatus == 1">精品</div>
      <img :src="it.videoThumbnail" alt="" />
      <!-- <div class="time">23:45</div> -->
    </div>
    <div class="dec">
      <div class="p">{{ it.videoTitle }}</div>
      <div class="name">{{ it.userNickName }}</div>
      <div class="ft">
        <div class="l">
          <svg class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-dianzan_kuai"></use>
          </svg>
          {{ it.loveNum }}
        </div>
        <div class="r">
          <svg class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-icon_play"></use>
          </svg>
          {{ it.playNum }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  components: {},
  mounted() {},
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      i: {
        img: "https://polyjetclub-backend.polysmartchain.com/images/normal/866.png",
        boutiqueStatus: 0, //	是否精品 1.是 0.否	integer
        createTime: "", //  创建时间	string
        id: "", //	视频id	integer
        loveNum: "", //  点赞量	integer
        playNum: "", //  播放量	integer
        updateTime: "", //	更新时间	string
        userNickName: "", //  用户昵称	string
        videoThumbnail: "", //	视频封面	string
        videoTitle: "", //  视频标题	string
      },
    };
  },
  methods: {
    // 前往视频详情
    goVideoDec() {
      console.log(this.it);
      this.store.actVideoId = this.it.id;
      window.sessionStorage.setItem("videoId", this.store.actVideoId);

      // 设置地址;
      Store.setActVideoId(this.it.id);
      this.$router.go(0);
    },
  },
};
</script>

<style lang="less" scoped>
.right-item {
  /* 矩形 14 */

  width: 400px;
  border-radius: 5px;
  // background: #ffffff;
  // box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  margin: 8px 0 8px;
  display: flex;
  .ind {
    /* 矩形 15 */
    background-image: url("../../assets/images/logo2.png");
    background-size: 100% 100%;
    width: 168px;
    height: 96px;
    // border-radius: 5px;
    background: #debfbf;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transform: scaleY(1.34);
    }
    .time {
      position: absolute;
      bottom: 10px;
      right: 9px;
      /* 34:16 */
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
    }
    .jing {
      position: absolute;
      top: 0;
      right: -0;
      width: 200px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background-color: #ff7d41d5;
      /* 精品 */
      font-family: PingFang SC Medium;
      font-size: 12px;
      color: #ffffff;
      transform: rotate(45deg) translate(32%, -40px);
    }
  }
  .dec {
    flex: 1;
    height: 96px;
    padding: 6px 10px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    .p {
      /* 稳定币发展历程稳定币发展历程稳定币发展历程... */
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
      flex: 1;
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .name {
      /* 大司马老师 */
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      margin-top: 10px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      margin-bottom: 6px;
    }
    .ft {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .l {
        flex: 1;
      }
      .r {
      }
    }
  }
}
</style>
