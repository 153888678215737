<template>
  <div class="video-box">
    <video
      controls
      preload="auto"
      :poster="store.actVideoDec.videoThumbnail"
      :src="store.actVideoDec.videoUrl"
      ref="video"
      :style="store.styleObject"
      @ended="upVactPop()"
      @loadedmetadata="getW()"
    ></video>
    <div class="dec">
      <div class="name">
        <!-- 视频标题 -->
        {{ store.actVideoDec.videoTitle }}
      </div>
      <div class="caozuo">
        <div class="user">
          <div class="d">
            <div class="item">{{ store.actVideoDec.playNum || 0 }} 次观看</div>
            <div class="item">{{ store.actVideoDec.loveNum || 0 }} 点赞</div>
            <div class="item">
              {{ uti.changeTime(store.actVideoDec.updateTime) || 0 }}
            </div>
          </div>
        </div>
        <div class="more">
          <div class="item">
            <a href="javascript:;" @click="vant()">
              <svg v-if="ifVant" class="icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-dianzan"></use>
              </svg>
              <svg v-else class="icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-dianzan_kuai"></use>
              </svg>
              <span>点赞</span>
            </a>
          </div>
          <div class="item">
            <a href="javascript:;" @click="upReward()">
              <svg class="icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-meiyoudashang"></use>
              </svg>

              <span>打赏</span>
            </a>
          </div>
          <div class="item" @click="addLove()">
            <a href="javascript:;">
              <svg
                v-if="store.actVideoDec.collected == 0"
                class="icon svg-icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-shoucang_shixin"></use>
              </svg>
              <svg
                v-if="store.actVideoDec.collected == 1"
                class="icon svg-icon tru"
                aria-hidden="true"
              >
                <use xlink:href="#icon-shoucang_shixin"></use>
              </svg>
              <span>收藏</span>
            </a>
          </div>
          <div class="item">
            <a
              href="javascript:;"
              class="hash"
              :data-clipboard-text="
                'http://polycollege.club/video?' + store.actVideoId
              "
              @click="doCopy()"
            >
              <svg class="green icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-fenxiang"></use>
              </svg>

              <span>分享</span>
            </a>
          </div>
          <div class="item">
            <a href="javascript:;" @click="upAppeal()">
              <svg class="icon svg-icon" aria-hidden="true">
                <use xlink:href="#icon-yonghushensu"></use>
              </svg>

              <span>申诉</span>
            </a>
          </div>
        </div>
      </div>

      <div class="userName">
        <!-- 用户名称 -->
        {{ store.actVideoDec.userNickName || "user" }}
        {{ `( ${uti.shrink(store.actVideoDec.userAddress, 6)} )` }}
        <a
          href="javascript:;"
          v-if="store.actVideoDec.userId != store.user.userId"
        >
          <div
            class="wl-but"
            v-if="store.actVideoDec.followed == 0"
            @click="addGZ()"
          >
            关注
          </div>
          <div
            class="wl-but but2"
            v-if="store.actVideoDec.followed == 1"
            @click="delGZ()"
          >
            已关注
          </div>
        </a>
      </div>
    </div>
    <reward ref="rew"></reward>
    <appeal ref="appeal"></appeal>
  </div>
</template>

<script>
import Store from "../../store";
import Clipboard from "clipboard";
import vantABI from "./vantABI";
import Reward from "./reward.vue";
import Appeal from "./appeal.vue";
export default {
  components: { Reward, Appeal },
  mounted() {
    // 构建网络
    this.web3 = new this.$web3("https://seed1.PolySmartChain.com");
    // 构建点赞合约
    this.vantCont = new this.web3.eth.Contract(vantABI, this.store.vantCont);
    // 获取是否可以点赞
    this.getIfVant();
    this.collected = this.store.actVideoDec.collected;
    // this.$refs.video;
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      // 是否不可以点赞
      ifVant: false,
      // 是否已经收藏
      collected: "", //	是否已收藏 1.是 0.否	integer(int32)
      web3: "",
      vantCont: "",
    };
  },
  methods: {
    // 获取视频宽度
    getW() {
      let w = this.$refs.video.videoWidth;
      let h = this.$refs.video.videoHeight;
      // console.log(w);
      // console.log(h);
      if (w > h) {
        // let mh = 720;
        console.log(window.innerWidth);
        // let h1 = (100 * h) / w;

        this.store.styleObject = {
          width: "100%",
          height: h + "%",
        };
      } else {
        this.store.styleObject = {
          width: w + "px",
          height: h + "px",
          display: "block",
          margin: "auto",
          maxHeight: "720px",
        };
      }
    },
    retHref() {
      window.location.href;
    },
    // 收藏视频/取消收藏视频
    async addLove() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let pat = "";
      if (this.store.actVideoDec.collected == 0) {
        pat = "/videoCollect/add";
        console.log("收藏");
      } else {
        pat = "/videoCollect/cancel";
        console.log("取消收藏");
      }
      let { data: res } = await this.$http.post(pat, {
        userId: this.store.user.userId,
        videoId: this.store.actVideoDec.id,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      if (pat == "/videoCollect/add") {
        this.store.actVideoDec.collected = 1;
      } else {
        this.store.actVideoDec.collected = 0;
      }
    },
    // 分享，复制链接
    doCopy() {
      // console.log(cp);
      var clipboard = new Clipboard(".hash");
      // console.log(clipboard);
      clipboard.on("success", () => {
        this.$message.success("分享链接已复制");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 不支持复制
        this.$message.error("error");
        // 释放内存
        clipboard.destroy();
      });
    },
    // 查询是否点过赞
    async getIfVant() {
      // 获取当前是否点赞
      // 0未点  1点了  likes()
      let res = await this.vantCont.methods
        .likes(this.store.user.address, this.store.actVideoId)
        .call({ from: Store.state.wallet });
      if (res == 1) {
        console.log("已经点赞 不可以点赞");
        this.ifVant = true;
      } else {
        console.log("没有点赞，可以点赞");
        this.ifVant = false;
      }
      return res;
    },
    // 点赞，通知后端
    async vant() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      if (this.ifVant) {
        return this.$message.warning("已经点赞");
      }
      let dt = await this.vantCont.methods
        .putLikesData(this.store.actVideoDec.id)
        .encodeABI();

      let trans = {
        from: this.store.user.address,
        to: this.store.vantCont,
        value: 0,
        data: dt,
      };

      console.log(trans);
      window.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [trans],
        })
        .then(() => {
          this.$message.success("点赞成功");
          this.ifVant = true;
          this.setVcant();
          this.store.actVideoDec.loveNum++;
        })
        .catch(() => {
          return this.$message.error("点赞失败");
        });
    },
    // 通知后端点赞了
    async setVcant() {
      let { data: res } = await this.$http.post(`/videoInfo/video/love`, {
        userId: this.store.user.userId,
        videoId: this.store.actVideoDec.id,
      });
      if (res.code !== 200) {
        return;
      }
    },
    // 打开打赏
    upReward() {
      this.$refs.rew.up();
    },
    // 打开申诉
    upAppeal() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      this.$refs.appeal.up();
    },
    // 添加关注
    async addGZ() {
      let { data: res } = await this.$http.post("/userFollow/follow", {
        targetUserId: this.store.actVideoDec.userId,
        // targetUserId: "1527493285113892864",
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.store.actVideoDec.followed = 1;
      this.$message.success("关注成功");
    },
    // 取消关注
    async delGZ() {
      let { data: res } = await this.$http.post("/userFollow/cancelFollow", {
        targetUserId: this.store.actVideoDec.userId,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.store.actVideoDec.followed = 0;

      this.$message.success("取消关注成功");
    },
    // 点赞弹窗
    upVactPop() {
      if (this.ifVant == 1) {
        return;
      }
      this.$confirm("观看结束，给作者点个赞？").then(() => {
        this.vant();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.video-box {
  width: calc(100%);
  video {
    max-height: 720px;
    object-fit: cover;
  }
  @media screen and (max-width: 1640px) {
    video {
      max-height: 582px;
    }
  }
  @media screen and (max-width: 1440px) {
    video {
      max-height: 469px;
    }
  }
  .dec {
    border-bottom: 1px solid #d8d8d8;
    padding: 10px 0;
    .name {
      margin-top: 0px;
      // height: 29px;
      font-family: SourceHanSansCN-Medium;
      font-size: 23px;
      font-weight: 500;
      letter-spacing: 0em;
      color: #333333;
    }
    .caozuo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .user {
        margin-top: 22px;
        .name {
          display: none;
          margin-top: 0;
          height: 23px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #666666;
          .wl-but {
            display: inline-block; /* 矩形 23 */
            width: 60px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            border-radius: 5px;
            margin-left: 55px;
            background: #ff7d41;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #ffffff;
          }
        }
        .d {
          margin-top: 19px;
          font-size: 12px;
          color: #999;
          display: flex;
          justify-content: space-between;
          .item {
            margin-right: 30px;
          }
        }
      }
      .more {
        align-self: flex-end;
        display: flex;
        .item {
          a {
            margin-left: 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333333;
          }
          svg {
            font-size: 24px;
            color: #333;
          }
          .tru {
            color: #ff7d41;
          }
          span {
            /* 点赞 */
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0em;
            color: #666;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.green {
  // color: rgb(13, 182, 13);
}
.userName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding-top: 10px;
  // margin-top: 0;
  height: 23px;
  font-family: SourceHanSansCN-Regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #666666;
  .wl-but {
    display: inline-block; /* 矩形 23 */
    width: 60px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 5px;
    margin-left: 55px;
    background: #ff7d41;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #ffffff;
  }
  .but2 {
    background-color: #fff !important;
    color: #ff7d41 !important;
    border: 1px solid #ff7d41;
  }
}
</style>
