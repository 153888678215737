<template>
  <div class="rewardBox">
    <el-dialog title="申诉原因" :visible.sync="ifshow" width="598px" center>
      <div class="box">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="textarea"
          :autosize="{ minRows: 6 }"
          maxlength="180"
          show-word-limit
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="textarea" type="primary" @click="appeal()"
          >提交申诉</el-button
        >
        <el-button v-else class="hui" type="primary">提交申诉</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示
      ifshow: false,
      act: "10",
      // 是否打开输入
      inputShow: false,
      input: "10",
      textarea: "",
      web3: "",
      shangCont: "",
    };
  },
  methods: {
    up() {
      this.ifshow = true;
    },
    down() {
      this.ifshow = false;
    },
    // 发起申诉
    async appeal() {
      if (!this.store.user) {
        return this.$message.error("请先登录");
      }
      let { data: res } = await this.$http.post("/videoComplaint/add", {
        // 用户id
        userId: this.store.user.userId,
        // 申诉内容
        complaintContent: this.textarea,
        // 视频id
        videoId: this.store.actVideoId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.down();
      this.$message.success("提交成功");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  /* 矩形 20 */

  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  /* 矩形 20 */
  width: 798px;
  padding-bottom: 30px;
  // height: 711px;
  .box {
    // width: 664px;
    margin: 0 auto;
    .user {
      display: flex;
      align-items: center;
      .text {
        margin-left: 13px;
        .name {
          height: 35px;
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #333333;
        }
        .addr {
          height: 27px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
    }
    .amountBox {
      display: flex;
      justify-content: space-between;
      margin-top: 49px;
      .amoutBut {
        width: 170px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #ff7d41;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        /* 10 PSC */

        font-family: SourceHanSansCN-Medium;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #ff7d41;
      }
      .act {
        background-color: #ff7d41;
        color: #ffffff;
      }
    }
    .el-input {
      margin-top: 49px;
      /* 矩形 23 */
      height: 180px;
      border-radius: 5px;
      box-sizing: border-box;
      // border: 1px solid #ff7d41;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      input {
        height: 90px;
        outline: none;
        padding-right: 20px;
        padding-left: 39px;
        font-size: 20px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input:focus {
        border: 1px solid #ff7d41;
      }
      .el-input__suffix {
        right: 50px;
        font-size: 20px;
      }
    }
  }
  .el-button {
    width: 100%;
    height: 60px;
    font-size: 24px;
    background-color: #ff7d41;
    border: none;
  }
  .hui {
    background-color: #999;
  }
}
</style>
