<template>
  <div class="video-box">
    <left-box-1 ref="lbox1"></left-box-1>
    <left-box-2></left-box-2>
  </div>
</template>

<script>
import Store from "../../store";
import LeftBox1 from "./leftBox1.vue";
import LeftBox2 from "./leftBox2.vue";
export default {
  components: { LeftBox1, LeftBox2 },
  mounted() {},

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.video-box {
  // background-color: red;
  // height: 500px;
  flex: 1;
  padding-right: 25px;

  // margin: 0 25px;
}
</style>
