import { render, staticRenderFns } from "./rightItem.vue?vue&type=template&id=49ed5c03&scoped=true&"
import script from "./rightItem.vue?vue&type=script&lang=js&"
export * from "./rightItem.vue?vue&type=script&lang=js&"
import style0 from "./rightItem.vue?vue&type=style&index=0&id=49ed5c03&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ed5c03",
  null
  
)

export default component.exports