<template>
  <div class="wl-video">
    <div class="w">
      <left-box ref="left"></left-box>
      <right-box></right-box>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import LeftBox from "./leftBox.vue";
import RightBox from "./rightBox.vue";
export default {
  components: { LeftBox, RightBox },
  mounted() {
    // if (window.sessionStorage.getItem("videoId")) {
    //   this.store.actVideoId = window.sessionStorage.getItem("videoId");
    //   window.sessionStorage.removeItem("videoId");
    // }
    // 获取传参
    let id = window.location.search.substr(1);
    if (id != "" && id.length > 5) {
      this.store.actVideoId = id;
    }

    // 获取视频详情
    this.getVideoDec();
    // 记录播放量
    this.setPlaybackVolume();
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      dt: {
        boutiqueStatus: "", //	是否精品 1.是 0.否	integer(int32)
        collected: "", //	是否已收藏 1.是 0.否	integer(int32)
        createTime: "", //	创建时间	string(date)
        id: "", //	视频id	integer(int64)
        loveNum: "", //	点赞量	integer(int32)
        loved: "", //	是否已点赞 1.是 0.否	integer(int32)
        playNum: "", //	播放量	integer(int32)
        updateTime: "", //	更新时间	string(date)
        userNickName: "", //	用户昵称	string
        videoThumbnail: "", //	视频封面	string
        videoTitle: "", //	视频标题	string
        videoUrl: "", //		视频播放地址
      },
    };
  },
  methods: {
    // 获取视频详情
    async getVideoDec() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoInfo/videoDetail/${this.store.user.userId || 0}/${
          this.store.actVideoId
        }`
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      // this.$message.success("操作成功");
      this.store.actVideoDec = res.data;
      this.getW();
    },

    // 记录播放量
    async setPlaybackVolume() {
      let { data: res } = await this.$http.post(
        `/videoInfo/video/add/play/${this.store.actVideoId}`
      );
      if (res.code !== 200) {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wl-video {
  .w {
    display: flex;
    padding-bottom: 50px;
  }
}
</style>
