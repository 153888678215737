<template>
  <div class="video-box">
    <div class="title">
      评论
      <span> ({{ total }}) </span>
    </div>
    <div class="commont">
      <div class="addCom">
        <!-- <el-avatar
          shape="square"
          src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
        ></el-avatar> -->
        <avatar-item></avatar-item>
        <el-input
          v-model="input"
          size="large"
          placeholder="添加一条评论"
        ></el-input>

        <el-button @click="comment()">发布</el-button>
      </div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getVideoComList()"
      >
        <left-comment-item
          @plsuc="hfSuc()"
          class="infinite-list-item"
          :it="item"
          v-for="(item, index) in store.actVideoComList"
          :key="index"
        ></left-comment-item>
      </van-list>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import AvatarItem from "../avatar/avatar-item.vue";
import LeftCommentItem from "./leftCommentItem.vue";
// import word from "../../sensitiveWord";
export default {
  components: { LeftCommentItem, AvatarItem },
  mounted() {
    this.getVideoComList();
    this.store.actVideoComList = [];
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      input: "",
      list: [{}, {}, {}, { list: [{}, {}] }],
      total: "",
      pageNum: 0,
      pageSize: 999,

      loading: false,
      finished: false,
    };
  },
  methods: {
    // 获取评论列表
    async getVideoComList() {
      this.pageNum++;
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/list/${this.store.actVideoId}`,
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          sort: 2,
          videoId: this.store.actVideoId,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.total = res.data.total;
      this.store.actVideoComList = this.uti.addArr(
        this.store.actVideoComList,
        res.data.records
      );
      this.loading = false;
      if (this.pageNum * this.pageNum >= this.total) {
        this.finished = true;
      }
    },
    // 评论视频
    async comment() {
      if (this.store.user.userId == "") {
        return this.$message.error("请先登录");
      }
      if (this.input == "") {
        return this.$message.error("评论不能为空");
      }
      // if (!word(this.input)) {
      //   return this.$message.error("标题包含敏感词汇");
      // }

      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/send/comment`,
        {
          userId: this.store.user.userId,
          // 评论内容
          contentText: this.input,
          // 视频id
          videoId: this.store.actVideoDec.id,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.input = "";
      this.pageNum = 0;
      this.store.actVideoComList = [];
      this.getVideoComList();
    },
    // 回复评论成功
    hfSuc() {
      this.pageNum = 0;
      this.store.actVideoComList = [];
      this.getVideoComList();
    },
    load() {
      this.count += 2;
      console.log(123);
    },
  },
};
</script>

<style lang="less" scoped>
.video-box {
  width: calc(100%);
  .title {
    margin-top: 25px;
    height: 35px;
    font-family: SourceHanSansCN-Medium;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #333333;
    span {
      /* (25) */
      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
  .commont {
    margin-top: 30px;
    .addCom {
      display: flex;
      .el-button {
        background-color: #864d33;
        color: #fff;
        height: 50px;
      }
      /deep/.el-avatar {
        width: 50px;
        height: 50px;
      }
      /deep/ .el-input {
        flex: 1;
        width: 1px;
        margin: 0 16px;
        height: 50px;
        input {
          height: 50px !important;
        }
      }
    }
  }
  .list {
    // height: 300px;
    overflow: auto;
  }
}
</style>
