<template>
  <div class="commentItem">
    <div class="itemBox">
      <!-- <el-avatar
        shape="square"
        :size="50"
        src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
      ></el-avatar> -->
      <avatar-item :addr="it.userId" :name="it.nickName"></avatar-item>
      <div class="box">
        <div class="name">{{ it.nickName }}</div>
        <div class="text">
          {{ it.contentText }}
        </div>

        <div class="hf" @click="hfIfShow = !hfIfShow">
          <a href="javascript:;"> 回复</a>
        </div>
        <div class="addCom" v-if="hfIfShow">
          <!-- <el-avatar
            shape="square"
            :size="50"
            src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
          ></el-avatar> -->
          <!-- <div class="wl-avatar">
            {{ uti.setAvatar(store.user.nickName) }}
          </div> -->
          <avatar-item></avatar-item>
          <el-input v-model="input" size="large" placeholder="回复"></el-input>

          <el-button @click="replyComments()">发布</el-button>
        </div>
        <left-item-item
          v-for="item in it.children"
          :key="item.id"
          :it="item"
        ></left-item-item>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import AvatarItem from "../avatar/avatar-item.vue";
import LeftItemItem from "./left-item-item.vue";
// import word from "../../sensitiveWord";
export default {
  name: "treeMenu",
  props: {
    it: {
      type: Object,
    },
  },
  components: { LeftItemItem, AvatarItem },
  mounted() {},
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      input: "",
      hfIfShow: false,
    };
  },
  methods: {
    // 回复评论
    async replyComments() {
      if (this.store.user.userId == "") {
        this.$message.error("请先登录");
      }
      // if (!word(this.input)) {
      //   return this.$message.error("标题包含敏感词汇");
      // }
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.post(
        `/videoComment/videoDetail/send/subComment`,
        {
          // 用户id
          userId: this.store.user.userId,
          // 评论id
          commentId: this.it.id,
          // 评论内容
          contentText: this.input,
          // 回复目标用户id
          targetUserId: this.it.userId,
        }
      );
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      this.input = "";
      this.$emit("plsuc");
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.commentItem {
  margin-top: 10px;
  .itemBox {
    display: flex;
    .wl-avatar {
      width: 46px;
    }
    .box {
      // width: 100%;
      flex: 1;
      margin-left: 16px;

      .name {
        /* 金牌讲师 */
        height: 20px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
      .text {
        height: 50px;
        margin-top: 10px;
        font-family: SourceHanSansCN-Regular;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #333333;
      }
      .hf {
        height: 20px;
        margin-bottom: 20px;
        margin-top: 0px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #999999;
      }
      .addCom {
        display: flex;
        margin-top: 10px;
        .el-button {
          background-color: #864d33;
          color: #fff;
          height: 50px;
        }
        /deep/ .el-input {
          // flex: 1;
          flex: 1;
          width: 1px;
          margin: 0 16px;
          height: 50px;
          input {
            height: 50px !important;
          }
        }
      }
    }
  }
}
</style>
