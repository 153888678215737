<template>
  <div class="video-box">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="精品" name="first"> </el-tab-pane>
      <!-- <el-tab-pane label="操作教程" name="second"> </el-tab-pane>
      <el-tab-pane label="体系介绍" name="third"> </el-tab-pane>
      <el-tab-pane label="其他" name="fourth"> </el-tab-pane> -->
      <el-tab-pane
        v-for="(item, index) in store.topics"
        :key="index"
        :label="item.title"
        :name="item.id + ''"
      >
      </el-tab-pane>
    </el-tabs>
    <div class="list">
      <right-item
        :it="item"
        v-for="(item, index) in videoList"
        :key="index"
      ></right-item>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import RightItem from "./rightItem.vue";
export default {
  components: { RightItem },
  mounted() {
    this.getVideoStateList();
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      activeName: "first",
      act: 1,
      videoList: [],
    };
  },
  methods: {
    // 选择某一个选项
    handleClick(tab, event) {
      console.log(tab.index);
      console.log(tab, event);
      // 如果是精品
      if (tab.index == 0) {
        this.getJVideoList();
      } else {
        this.act = this.store.topics[tab.index - 1].id;
        this.getVideoList();
      }
    },
    // 获取视频分类
    async getVideoStateList() {
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let { data: res } = await this.$http.get(`/profileTopic/topics/${1}`, {});
      if (res.code !== 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.store.topics = res.data;
      this.getJVideoList();
    },
    // 获取精品视频列表
    async getJVideoList() {
      let { data: res } = await this.$http.post("/videoInfo/boutiqueVideos", {
        pageNum: 1,
        pageSize: 100,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.videoList = res.data.records;
      console.log(this.videoList);
    },
    // 获取视频列表
    async getVideoList() {
      let { data: res } = await this.$http.post("/videoInfo/topicVideos", {
        pageNum: 1,
        pageSize: 100,
        topicId: this.act,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.videoList = res.data.records;
      console.log(this.videoList);
    },
  },
};
</script>

<style lang="less" scoped>
.video-box {
  width: 400px;
  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #d8d8d8;
    /* 操作教程 */
    .item {
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      transition: 0.3s;
      a {
        color: #999999;
      }
    }
    .act {
      /* 精品 */
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0em;
      color: #333333;
      a {
        color: #333333;
      }
    }
  }
}
</style>
